
import { defineComponent, onMounted, ref, watch } from "vue";
import { ErrorMessage, Field, Form, useField } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import moment from "moment";
import { ElMessageBox } from "element-plus";
import { checkNews, changeScraperNews } from "./streams";
import Swal from "sweetalert2/dist/sweetalert2.js";
interface MediaCetakForm {
  title: string;
  source: string;
  account: string;
  program: string;
  content: string;
  date: string;
  emotion: string;
  file_source: string;
  journalist: string;
  page: string;
  rate_bw: string;
  rate_fc: string;
  sentiment: string;
  is_news_photo: string;
  ad_value: any;
  duration: any;
  jenis_data: string;
}

export default defineComponent({
  name: "input-berita",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const form = ref();
    const upload = ref();
    const isLoading = ref(false);
    const is_show_alert = ref(false);
    const inserted_id = ref("");
    const is_scraper_edit = ref(false);
    const scraper_id = ref(null);
    const submitButton1 = ref<HTMLElement | null>(null);
    const getDataButton1 = ref<HTMLElement | null>(null);
    const isCopied = ref(false);
    const loading = ref(false);
    const isRateBwFc = ref(false);
    const isColumnBw = ref(false);
    const isColumnFc = ref(false);
    const select_rate = ref("");
    const media_options = ref([]);
    const stasiun_options = ref([]);
    const file_list = ref([]);
    const router = useRouter();
    const valueContent = ref("");
    const keyRateFc = ref(0);
    const keyRateBw = ref(0);
    const rateAccountMedia = ref(0);
    const lebarAccountMedia = ref(0);
    const columnBw = ref("");
    const columnFc = ref("");
    const dataAccountMedia = ref({});
    const isEnableField = ref(true);
    const file_upload_header = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });

    // Media Cetak
    const lebarBW = ref(0);
    const volumeBW = ref(0);
    const rateAdBW = ref(0);

    const lebarFC = ref(0);
    const volumeFC = ref(0);
    const rateAdFC = ref(0);

    // Media TV
    const rate_perdetik = ref(0);
    const ppn = ref(0);
    
    const actionURL = `${process.env.VUE_APP_API_URL}/streams/uploadfile`;
    const MediaCetakForm = ref<MediaCetakForm>({
      title: "",
      source: "",
      account: "",
      program: "",
      content: "",
      date: "",
      emotion: "",
      file_source: "",
      journalist: "",
      page: "",
      rate_bw: "",
      rate_fc: "",
      sentiment: "",
      is_news_photo: "",
      jenis_data: "",
      ad_value: "0",
      duration: "0"
    });
    const MediaCetakFormValidator = Yup.object().shape({
      title: Yup.string().required().label("Judul Berita"),
      source: Yup.string().required().label("Sumber Berita"),
      account: Yup.string().when("source", {
        is: () => MediaCetakForm.value.account === "",
        then: Yup.string().required("Nama Media is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      is_news_photo: Yup.string().when("source", {
        is: () =>
          MediaCetakForm.value.source === "media_cetak" &&
          MediaCetakForm.value.is_news_photo == null,
        then: Yup.string().required(
          "Keterangan berita bergambar is a required field"
        ),
        otherwise: Yup.string().notRequired(),
      }),
      jenis_data: Yup.string().when("source", {
        is: () =>
          MediaCetakForm.value.source === "media_tv" &&
          MediaCetakForm.value.jenis_data == "",
        then: Yup.string().required("Jenis data is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      ad_value: Yup.string().when("source", {
        is: () => MediaCetakForm.value.source === "media_tv",
        then: Yup.string()
          .required("AD Value is a required field")
          .test("is-not-zero", "Nilai tidak boleh 0", (value) => value !== "0"),
        otherwise: Yup.string().notRequired(),
      }),
      duration: Yup.string().when("source", {
        is: () => MediaCetakForm.value.source === "media_tv",
        then: Yup.string()
          .required("Duration is a required field")
          .test("is-not-zero", "Nilai tidak boleh 0", (value) => value !== "0"),
        otherwise: Yup.string().notRequired(),
      }),
      program: Yup.string().when("source", {
        is: (val) => val === "media_tv",
        then: Yup.string().required("Program"),
        otherwise: Yup.string().notRequired(),
      }),
      columnFc: Yup.string().when("account", {
        is: (val) => isColumnFc.value,
        then: Yup.string()
          .required("Kolom Berwarna"),
        otherwise: Yup.string().notRequired(),
      }),
      columnBW: Yup.string().when("account", {
        is: (val) => isColumnBw.value,
        then: Yup.string()
          .required("Kolom Hitam Putih"),
        otherwise: Yup.string().notRequired(),
      }),
      content: Yup.string().when("source", {
        is: (val) => MediaCetakForm.value.content === "",
        then: Yup.string().required("Content is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      date: Yup.string().when("source", {
        is: () => MediaCetakForm.value.date === "",
        then: Yup.string().required("Tanggal Berita is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      emotion: Yup.string().label("Emotion"),
      file_source: Yup.string().required().label("File Source"),
      // journalist: Yup.string().when("source", {
      //   is: () => MediaCetakForm.value.journalist === "",
      //   then: Yup.string().required("journalist is a required field"),
      //   otherwise: Yup.string().notRequired(),
      // }),
      page: Yup.string().when("source", {
        is: (val) => val === "media_cetak",
        then: Yup.string().required("Page"),
        otherwise: Yup.string().notRequired(),
      }),
      rate_bw: Yup.string().when("source", {
        is: (val) => val === "media_cetak",
        then: Yup.string()
          .required("Rate Hitam Putih"),
        otherwise: Yup.string().notRequired(),
      }),
      rate_fc: Yup.string().when("source", {
        is: (val) => val === "media_cetak",
        then: Yup.string()
          .required("Rate Berwarna"),
        otherwise: Yup.string().notRequired(),
      }),
      sentiment: Yup.string().label("Sentimen"),
    });
    const checkADValueFromDuration = () => {
      rate_perdetik.value = 0;
      ppn.value = 0;

      if (
        MediaCetakForm.value.account &&
        MediaCetakForm.value.date &&
        MediaCetakForm.value.duration
      ) {
        ApiService.post("/streams/rumus-ad-value", {
          data: {
            nama_media: MediaCetakForm.value.account,
            publish_date: MediaCetakForm.value.date,
            durasi_video: Number(MediaCetakForm.value.duration.toString().replace(/[.]/g, "")),
          },
        })
          .then((res) => {
            MediaCetakForm.value.ad_value = thousandSeparator(res.data.ad_value) || "0";
            rate_perdetik.value = thousandSeparator(res.data.rate_per_detik) || "0";
            ppn.value = res.data.ppn || 0;
          })
          .catch(() => {
            MediaCetakForm.value.ad_value = "0";
            rate_perdetik.value = 0;
            ppn.value = 0;
          });
      }
    };
    const formatFileSource = (file_list) => {
      let file_source = "";
      for (let i = 0; i < file_list.length; i++) {
        if (i == 0) {
          file_source = file_list[i].response.file;
        } else {
          file_source = file_source + "," + file_list[i].response.file;
        }
      }
      return file_source;
    };
    const isNumber = (evt) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || charCode !== 190)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    };
    const onPaste = (evt) => {
      let value = evt.clipboardData.getData("text");
      if (value.match(/^[0-9]+$/) != null) {
        if (evt.currentTarget.id == "rateBw") {
          MediaCetakForm.value.rate_bw = value;
          keyRateBw.value += 1;
        } else {
          MediaCetakForm.value.rate_fc = value;
          keyRateFc.value += 1;
        }
      } else {
        if (evt.currentTarget.id == "rateBw") {
          MediaCetakForm.value.rate_bw = "";
          keyRateBw.value += 1;
        } else {
          MediaCetakForm.value.rate_fc = "";
          keyRateFc.value += 1;
        }
      }
    };
    const resetForm = () => {
      form.value.resetForm();
      if (MediaCetakForm.value.source != "news") {
        upload.value.clearFiles();
      }
      columnBw.value = "";
      columnFc.value = "";
      MediaCetakForm.value = {
        title: "",
        source: "",
        account: "",
        program: "",
        content: "",
        date: "",
        emotion: "",
        file_source: "",
        journalist: "",
        page: "",
        rate_bw: "",
        rate_fc: "",
        sentiment: "",
        is_news_photo: "",
        jenis_data: "",
        ad_value: "0",
        duration: "0",
      };
    };
    const addNews = async () => {
      if (submitButton1.value) {
        let loadout = MediaCetakForm.value;
        
        loadout.ad_value = Number(loadout.ad_value.toString().replace(/[.]/g, ""));
        loadout.rate_bw = loadout.rate_bw.toString().replace(/[.]/g, "");
        loadout.rate_fc = loadout.rate_fc.toString().replace(/[.]/g, "");

        if (loadout.source != "news") {
          loadout.file_source = formatFileSource(file_list.value);
        }
        loadout.date = moment(loadout.date).format("YYYY-MM-DD HH:mm:ss");

        if (loadout.source == "media_tv") {
          loadout.duration = Number(loadout.duration.toString().replace(/[.]/g, ""));
        }

        if (loadout.source == 'media_cetak' && (loadout.rate_bw == '0' && loadout.rate_fc == '0')) {
          Swal.fire({
            position: "top-end",
            icon: "error",
            toast: true,
            title: "Mohon Periksa Kolom Ad Value",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          isLoading.value = true;
          // Activate indicator
          submitButton1.value.setAttribute("data-kt-indicator", "on");

          if (is_scraper_edit.value) {
            const data = {
              title: MediaCetakForm.value.title,
              content: MediaCetakForm.value.content,
              account: MediaCetakForm.value.account,
              date: MediaCetakForm.value.date,
              journalist: MediaCetakForm.value.journalist,
            };
            const changes_value = await changeScraperNews(scraper_id.value, data);
            if (
              changes_value["message"] == "Berhasil" ||
              changes_value["message"] == "Nochanges"
            ) {
              Swal.fire({
                position: "top-end",
                icon: "success",
                toast: true,
                title: "Berhasil! Berita berhasil disimpan",
                showConfirmButton: false,
                timer: 3000,
              });
              submitButton1.value?.removeAttribute("data-kt-indicator");
            } else {
              Swal.fire({
                position: "top-end",
                icon: "error",
                toast: true,
                title: "Gagal! Berita gagal disimpan",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          } else {
            ApiService.post("/streams/save", { data: loadout })
              .then((response) => {
                if (response.status == 200) {
                  inserted_id.value = response.data.inserted_id;
                  is_show_alert.value = true;
                  setTimeout(() => {
                    is_show_alert.value = false;
                  }, 30000);
                  Swal.fire({
                    position: "top-end",
                    icon: "success",
                    toast: true,
                    title: "Berhasil! Berita berhasil disimpan",
                    showConfirmButton: false,
                    timer: 3000,
                  });
                  resetForm();
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                } else {
                  Swal.fire({
                    position: "top-end",
                    icon: "error",
                    toast: true,
                    title: "Gagal! Berita gagal disimpan",
                    showConfirmButton: false,
                    timer: 3000,
                  });
                }
              })
              .catch((error) => {
                Swal.fire({
                  title: "Gagal!",
                  text: `${
                    error.message
                      ? error.message
                      : "Terjadi kesalahan saat menyimpan berita"
                  }`,
                  icon: "error",
                  confirmButtonText: "Ok",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-light-primary",
                  },
                });
              })
              .finally(() => {
                // Deactivate indicator
                isLoading.value = false;
                submitButton1.value?.removeAttribute("data-kt-indicator");
              });
          }
        }
      }
    };
    const showDetail = () => {
      window.open("/berita/detail/" + inserted_id.value);
    };
    const setFileUpload = (response, file, fileList) => {
      file_list.value = fileList;
      if (fileList.length > 0) {
        MediaCetakForm.value.file_source = response.file;
      }
    };
    const removeFileUpload = (file, fileList) => {
      const file_to_remove = file.response.file;
      ApiService.post("/streams/removefile", { data: { path: file_to_remove } })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const errorFileUpload = (error, file, fileList) => {
      let message = JSON.parse(error.message);
      ElMessageBox.alert("Silakan periksa inputan Anda.", message.detail, {
        confirmButtonText: "OK",
        type: "warning",
        center: true,
      });
    };
    const remoteMethod = (query) => {
      if (query.length > 2) {
        loading.value = true;
        let url = "/media?media_like=" + query + "&limit=100&page=1";
        if (MediaCetakForm.value["source"] != "") {
          url = url + "&source=" + MediaCetakForm.value["source"];
        }
        ApiService.get(url)
          .then((response) => {
            media_options.value = response.data.data;
            return media_options.value;
          })
          .catch((error) => {
            console.log(error);
            loading.value = false;
          })
          .finally(() => {
            loading.value = false;
          });
        return [];
      } else {
        media_options.value = [];
      }
    };
    const remoteMethodTV = (query) => {
      if (query.length > 2) {
        loading.value = true;
        let url =
          "/media/stasiuntv?stasiun_like=" + query + "&limit=100&page=1";
        if (MediaCetakForm.value["source"] != "") {
          url = url + "&source=" + MediaCetakForm.value["source"];
        }
        ApiService.get(url)
          .then((response) => {
            stasiun_options.value = response.data.data;
            return stasiun_options.value;
          })
          .catch((error) => {
            console.log(error);
            loading.value = false;
          })
          .finally(() => {
            loading.value = false;
          });
        return [];
      } else {
        stasiun_options.value = [];
      }
    };
    const cekFileSource = (file, fileList) => {
      if (fileList.length == 0) {
        MediaCetakForm.value["file_source"] = "";
      }
    };
    const convertText = (valueContent) => {
      if (MediaCetakForm.value["content"] != null) {
        let text = MediaCetakForm.value["content"].split("\n\n");
        let newText = "";

        for (let i = 0; i < text.length; i++) {
          let perTextArray = text[i].split("\n");
          for (let j = 0; j < perTextArray.length; j++) {
            if (perTextArray[j].charAt(perTextArray[j].length - 1) == "-") {
              newText += perTextArray[j].replace(/-/g, "");
            } else {
              newText += perTextArray[j] + " ";
            }
          }
          newText += "\n";
        }
        MediaCetakForm.value.content = newText;
        valueContent = newText;
      }
    };
    const changeAccount = (data) => {
      if ("bw" in data) {
        if (data.bw.rate > 0) {
          isColumnBw.value = true;
        } else {
          isColumnBw.value = false;
        }
      } else {
        isColumnBw.value = false;
        MediaCetakForm.value.rate_bw = "0";
        columnBw.value = "0";
      }

      if ("fc" in data) {
        if (data.fc.rate > 0) {
          isColumnFc.value = true;
        } else {
          isColumnFc.value = false;
        }
      } else {
        MediaCetakForm.value.rate_fc = "0";
        isColumnFc.value = false;
        columnFc.value = "0";
      }
      dataAccountMedia.value = data;

      lebarBW.value = thousandSeparator(dataAccountMedia.value["bw"]["lebar"]);
      rateAdBW.value = thousandSeparator(dataAccountMedia.value["bw"]["rate"]);

      lebarFC.value = thousandSeparator(dataAccountMedia.value["fc"]["lebar"]);
      rateAdFC.value = thousandSeparator(dataAccountMedia.value["fc"]["rate"]);

    };
    const countAD = (type) => {
      let lebar = 0;
      let rate = 0;
      let valueAD = 0;
      if (type == "bw") {
        lebar = dataAccountMedia.value["bw"]["lebar"];
        rate = dataAccountMedia.value["bw"]["rate"];
        const volume = Number(columnBw.value.toString().replace(/[.]/g, "")) * lebar;
        volumeBW.value = thousandSeparator(volume);
        valueAD = Number(columnBw.value.toString().replace(/[.]/g, "")) * lebar * rate;
        MediaCetakForm.value.rate_bw = thousandSeparator(valueAD);        
      } else {
        lebar = dataAccountMedia.value["fc"]["lebar"];
        rate = dataAccountMedia.value["fc"]["rate"];
        const volume = Number(columnFc.value.toString().replace(/[.]/g, "")) * lebar;
        volumeFC.value = thousandSeparator(volume);
        valueAD = Number(columnFc.value.toString().replace(/[.]/g, "")) * lebar * rate;
        MediaCetakForm.value.rate_fc = thousandSeparator(valueAD);
      }
    };
    const goToRoute = (id: string, name: string, detail: boolean) => {
      if (detail) {
        window.open("/berita/detail/" + id);
      } else {
        router.push({ name: name, params: { id: id } });
      }
    };
    const checkContent = async () => {
      isEnableField.value = false;
      is_scraper_edit.value = false;
      getDataButton1.value?.setAttribute("data-kt-indicator", "on");
      getDataButton1.value?.setAttribute("disabled", "disabled");
      MediaCetakForm.value.content = "";
      valueContent.value = "";
      MediaCetakForm.value.account = "";
      MediaCetakForm.value.title = "";
      MediaCetakForm.value.date = "";
      MediaCetakForm.value.journalist = "";
      let params = {
        url: MediaCetakForm.value.file_source.trim(),
      };
      let response = await checkNews(params);
      if (response["error"] || response["result"].error) {
        Swal.fire({
          text: "Gagal Mendapatkan Informasi Berita ! Silahkan Masukkan URL Berita Lain...",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary",
          },
        });
        getDataButton1.value?.removeAttribute("data-kt-indicator");
        getDataButton1.value?.removeAttribute("disabled");
      } else {
        const data = response["result"];
        if (data.siputra.status) {
          Swal.fire({
            title: "Berita ditemukan di database SIPUTRA!",
            html: "Apakah anda ingin mengubah berita tersebut?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Tidak",
            confirmButtonText: "Ya, Ubah",
            allowOutsideClick: false,
            width: "500px",
          }).then(async (result) => {
            if (result.isConfirmed) {
              goToRoute(data.siputra.data._id, "berita-edit", false);
            } else {
              MediaCetakForm.value.file_source = "";
              getDataButton1.value?.removeAttribute("data-kt-indicator");
              getDataButton1.value?.removeAttribute("disabled");
            }
          });
        } else if (data.scraper.status) {
          Swal.fire({
            title: "Berita ditemukan di database SCRAPER!",
            html: "Apakah anda ingin mengubah berita tersebut?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Tidak",
            confirmButtonText: "Ya, Ubah",
            allowOutsideClick: false,
            width: "500px",
          }).then(async (result) => {
            if (result.isConfirmed) {
              const scrap_data = data.scraper.data;
              is_scraper_edit.value = true;
              scraper_id.value = scrap_data._id;
              MediaCetakForm.value.content = scrap_data.content;
              valueContent.value = scrap_data.content;
              MediaCetakForm.value.account = scrap_data.account;
              MediaCetakForm.value.title = scrap_data.title;
              MediaCetakForm.value.date = scrap_data.date;
              MediaCetakForm.value.journalist = scrap_data.journalist;
              isEnableField.value = false;
              getDataButton1.value?.removeAttribute("data-kt-indicator");
              getDataButton1.value?.removeAttribute("disabled");
            } else {
              MediaCetakForm.value.file_source = "";
              getDataButton1.value?.removeAttribute("data-kt-indicator");
              getDataButton1.value?.removeAttribute("disabled");
            }
          });
        } else {
          getContentUrl();
        }
      }
    };
    const getContentUrl = () => {
      getDataButton1.value?.setAttribute("data-kt-indicator", "on");
      getDataButton1.value?.setAttribute("disabled", "disabled");
      const data = async (payload) => {
        return await ApiService.post("/streams", { data: payload });
      };
      let url = "/crawler/newspaper";

      ApiService.post(url, {
        data: { url: MediaCetakForm.value.file_source.trim() },
      })
        .then((response) => {
          if (response.status == 200) {
            MediaCetakForm.value.content = response.data.content;
            valueContent.value = response.data.content;
            MediaCetakForm.value.account = response.data.account;
            MediaCetakForm.value.title = response.data.title;
            MediaCetakForm.value.date = response.data.date;
            MediaCetakForm.value.journalist = response.data.journalist;
            isEnableField.value = false;
            getDataButton1.value?.removeAttribute("data-kt-indicator");
            getDataButton1.value?.removeAttribute("disabled");
          } else {
            isEnableField.value = false;
            getDataButton1.value?.removeAttribute("data-kt-indicator");
            getDataButton1.value?.removeAttribute("disabled");
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            text: "Gagal Mendapatkan Informasi Berita ! Silahkan Masukkan URL Berita Lain...",
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          });
          isEnableField.value = false;
          getDataButton1.value?.removeAttribute("data-kt-indicator");
          getDataButton1.value?.removeAttribute("disabled");
        });
    };

    const thousandSeparator = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Input Berita", ["Berita"]);
    });

    return {
      form,
      upload,
      is_show_alert,
      isLoading,
      inserted_id,
      scraper_id,
      is_scraper_edit,
      checkADValueFromDuration,
      goToRoute,
      submitButton1,
      addNews,
      showDetail,
      resetForm,
      MediaCetakForm,
      MediaCetakFormValidator,
      setFileUpload,
      loading,
      remoteMethod,
      remoteMethodTV,
      media_options,
      stasiun_options,
      removeFileUpload,
      file_list,
      cekFileSource,
      file_upload_header,
      actionURL,
      errorFileUpload,
      convertText,
      valueContent,
      isNumber,
      onPaste,
      keyRateFc,
      keyRateBw,
      isRateBwFc,
      changeAccount,
      select_rate,
      countAD,
      rateAccountMedia,
      lebarAccountMedia,
      columnBw,
      isColumnBw,
      isColumnFc,
      columnFc,
      getContentUrl,
      checkContent,
      isEnableField,
      getDataButton1,
      isCopied,
      lebarBW,
      volumeBW,
      rateAdBW,
      lebarFC,
      volumeFC,
      rateAdFC,
      rate_perdetik,
      ppn,
    };
  },
  methods: {
    setEmotion(value) {
      if (value == this.MediaCetakForm.emotion) {
        this.MediaCetakForm.emotion = "";
      } else {
        this.MediaCetakForm.emotion = value;
      }
    },
    setSentiment(value) {
      if (value == this.MediaCetakForm.sentiment) {
        this.MediaCetakForm.sentiment = "";
      } else {
        this.MediaCetakForm.sentiment = value;
      }
    },
    thousandSeparator(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    handleInputBW() {
      this.MediaCetakForm.rate_bw = this.MediaCetakForm.rate_bw.replace(
        /[.]/g,
        ""
      );
    },
    handleInputFC() {
      this.MediaCetakForm.rate_fc = this.MediaCetakForm.rate_fc.replace(
        /[.]/g,
        ""
      );
    },
    handleInputAdValue() {
      this.MediaCetakForm.ad_value = this.MediaCetakForm.ad_value.toString().replace(
        /[.]/g,
        ""
      );
    },
    handleChangeBW(event) {
      this.MediaCetakForm.rate_bw = this.thousandSeparator(event.target.value);
    },
    handleChangeFC(event) {
      this.MediaCetakForm.rate_fc = this.thousandSeparator(event.target.value);
    },
    handleAdValue(event) {
      this.MediaCetakForm.ad_value = this.thousandSeparator(event.target.value);
    },
    handleInputColomBW() {
      this.columnBw = this.columnBw.toString().replace(/[.]/g, "");
    },
    handleChangeColomBW(event) {
      this.columnBw = this.thousandSeparator(event.target.value);
    },
    handleInputColomFC() {
      this.columnFc = this.columnFc.toString().replace(/[.]/g, "");
    },
    handleChangeColomFC(event) {
      this.columnFc = this.thousandSeparator(event.target.value);
    },
    handleInputDurasiVideo() {
      this.MediaCetakForm.duration = this.MediaCetakForm.duration.toString().replace(/[.]/g, "");
    },
    handleChangeDurasiVideo(event) {
      this.MediaCetakForm.duration = this.thousandSeparator(event.target.value);
    },
    copyText() {
      this.isCopied = true;
      var copyText = document.getElementById(
        "copy-clipboard"
      ) as HTMLInputElement;
      copyText.select();
      document.execCommand("copy");
    },
  },
  computed: {
    acceptFile() {
      let str_accept_file = "image/png, image/jpeg, image/jpg";
      if (this.MediaCetakForm["source"] == "media_tv") {
        str_accept_file = "video/mp4,video/x-m4v";
      }
      return str_accept_file;
    },
  },
});
